import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { i18n } from 'next-i18next';
import cookie from 'cookie';
import OnAirPage from '@sy/app-client/src/ssrAdapter/pages/OnAirPage';

import { OnAirApiError } from '~/services/onair/types/Webinar';
import { getWebinarById, getUserRegistrationId } from '~/services/onair/client';

import buildMetatadata from '~/utils/buildMetadata';
import { setCookieRegistrationId } from '~/getServerSideProps/onAir/contextDecorators/withAuth';
import { InferProps, NOT_FOUND, translate } from '~/getServerSideProps';
import {
	buildOnAirGetServerSideProps,
	OnAirPageContext,
} from '~/getServerSideProps/onAir';
import { getAllFrontendVars } from '@sy/frontend-bridge-resources';
import { getEnvironment } from '~/config.environment';

const OnAir: NextPage<InferProps<typeof getServerSideProps>> = ({
	isEmbedded,
	jwt,
	sessionRegistrationId,
	webinarId,
	sessionCountryCode,
}) => (
	<OnAirPage
		{...{
			i18n,
			router: useRouter(),
			isEmbedded,
			sessionRegistrationId,
			jwt,
			webinarId,
			sessionCountryCode,
		}}
	/>
);

const customServerSideProps = async (context: OnAirPageContext) => {
	const { authOnAir, locale, params, query, req } = context;
	const { webinarId } = params || {};
	const { embed = '', token = '' } = query || {};

	if (typeof webinarId !== 'string' || !webinarId.trim()) {
		return NOT_FOUND;
	}

	try {
		const { userId = '', sessionId = '' } = authOnAir;

		const registrationId = getUserRegistrationId({
			webinarId,
			token,
			registrationId: userId,
		});

		const webinar = await getWebinarById(webinarId, sessionId);

		if (
			webinar.isRegistrationEnabled &&
			(userId || token) &&
			registrationId !== userId
		) {
			setCookieRegistrationId(context, registrationId);
		}

		const onAirCookies = cookie.parse(req.headers.cookie || '');

		const { host } = req.headers;

		// Default to 'en' if Cf-Ipcountry header is not present
		const ipCountry = req.headers['cf-ipcountry'] ?? 'US';

		const { ASSETS_HOST } = getAllFrontendVars(getEnvironment())! || {};

		const defaultOnAirOgImage = `${ASSETS_HOST}/images/v1/og_introductory_webinar_1200x630.png`;
		const ogImgUrl =
			webinar.coverImageUrl || webinar.vodPosterUrl || defaultOnAirOgImage;

		return {
			props: {
				webinarId,
				isEmbedded: !!embed,
				jwt: (!!embed && onAirCookies) || {}, // only pass jwt if embedded
				sessionRegistrationId: registrationId,
				sessionCountryCode: ipCountry,
				metadata: buildMetatadata({
					title: webinar?.title?.trim()
						? webinar?.title
						: translate(locale, 'common.title.onAir.watch'),
					description: webinar?.description?.trim()
						? webinar?.description
						: translate(locale, 'common.description.onAir.watch'),
					ogImgUrl,
					ogUrl: `https://${host}/watch/${webinarId}`,
					twitterImgUrl: ogImgUrl,
				}),
			},
		};
	} catch (err) {
		const { statusCode } = err as OnAirApiError;
		if ([400, 404, 410].includes(statusCode)) {
			return NOT_FOUND;
		}

		throw err;
	}
};

export default OnAir;

export const getServerSideProps = buildOnAirGetServerSideProps({
	customServerSideProps,
	translationNamespaces: ['common', 'destinations', 'broadcasts'],
});
