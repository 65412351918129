import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_without_properties from "@swc/helpers/src/_object_without_properties.mjs";
import { useCallback, useEffect, useRef } from "react";
import logger, { castUnknownToError } from "~/services/logger";
import { ON_AIR_SUBSCRIBE } from "../constants";
// https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent/code
var CLOSE_NORMAL_STATUS_CODE = 1000;
var randomIntFromInterval = function(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
};
var useWebsocket = function(_param) {
    var url = _param.url, enabled = _param.enabled, isViewerCountEnabled = _param.isViewerCountEnabled, sessionRegistrationId = _param.sessionRegistrationId, heartbeatData = _param.heartbeatData, cb = _object_without_properties(_param, [
        "url",
        "enabled",
        "isViewerCountEnabled",
        "sessionRegistrationId",
        "heartbeatData"
    ]);
    var socketRef = useRef(null);
    var optionsCache = useRef(cb);
    optionsCache.current = cb;
    var activeSubscribers = useRef({
        VIEWERS_COUNT_CHANNEL: false,
        WEBINAR_STATUS_CHANNEL: false,
        WEBINAR_INVITATION_CHANNEL: false
    });
    var subscribeToEvent = useCallback(function(channel) {
        if (socketRef.current && !activeSubscribers.current[channel]) {
            socketRef.current.send(ON_AIR_SUBSCRIBE[channel]);
            activeSubscribers.current[channel] = true;
        }
    }, [
        activeSubscribers
    ]);
    var unsubscribeFromEvent = useCallback(function(channel) {
        if (socketRef.current && activeSubscribers.current[channel]) {
            socketRef.current.send(ON_AIR_SUBSCRIBE["".concat(channel, "_UNSUBSCRIBE")]);
            activeSubscribers.current[channel] = false;
        }
    }, [
        activeSubscribers
    ]);
    var deactivateAllSubscribers = useCallback(function() {
        Object.keys(activeSubscribers.current).forEach(function(channel) {
            if (activeSubscribers.current[channel]) {
                activeSubscribers.current[channel] = false;
            }
        });
    }, [
        activeSubscribers
    ]);
    useEffect(function() {
        var action = isViewerCountEnabled ? subscribeToEvent : unsubscribeFromEvent;
        action("VIEWERS_COUNT_CHANNEL");
    }, [
        isViewerCountEnabled,
        subscribeToEvent,
        unsubscribeFromEvent
    ]);
    useEffect(function() {
        var retryWithBackoffStrategy = function(fn, param) {
            var _retryCount = param.retryCount, retryCount = _retryCount === void 0 ? 1 : _retryCount, retryReason = param.retryReason;
            var retryTimeout = Math.min(ON_AIR_SUBSCRIBE.MAXIMUM_BACKOFF_MILLISECONDS, Math.pow(2, retryCount) * 1000);
            logger.info("watchPage::Connection closed, retrying...", _object_spread({
                retryTimeout: retryTimeout
            }, retryReason));
            socketRef.current = null;
            // fn();
            setTimeout(fn, retryTimeout);
        };
        var connect = function() {
            var retryIteration = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 0;
            var retryCount = retryIteration || 0;
            var heartbeatIntervalId;
            var socket = new WebSocket(url);
            socketRef.current = socket;
            socket.onopen = function() {
                var _current, ref;
                (ref = (_current = optionsCache.current).onOpen) === null || ref === void 0 ? void 0 : ref.call(_current, socket);
                if (isViewerCountEnabled) {
                    subscribeToEvent("VIEWERS_COUNT_CHANNEL");
                }
                subscribeToEvent("WEBINAR_STATUS_CHANNEL");
                subscribeToEvent("WEBINAR_INVITATION_CHANNEL");
                if (heartbeatIntervalId) {
                    clearInterval(heartbeatIntervalId);
                }
                heartbeatIntervalId = setInterval(function() {
                    // Send heartbeat every 15 to 25 seconds
                    socket.send(ON_AIR_SUBSCRIBE.HEARTBEAT_COMMAND(heartbeatData));
                }, randomIntFromInterval(15000, 25000));
                retryCount = 0;
            };
            socket.onmessage = function(event) {
                if (event && event.data) {
                    var _current, ref;
                    (ref = (_current = optionsCache.current).onMessage) === null || ref === void 0 ? void 0 : ref.call(_current, JSON.parse(event.data));
                }
            };
            socket.onclose = function(param) {
                var code = param.code, reason = param.reason, wasClean = param.wasClean;
                var _current, ref;
                (ref = (_current = optionsCache.current).onClose) === null || ref === void 0 ? void 0 : ref.call(_current, code);
                deactivateAllSubscribers();
                if (enabled && code !== CLOSE_NORMAL_STATUS_CODE) {
                    if (heartbeatIntervalId) {
                        clearInterval(heartbeatIntervalId);
                    }
                    retryWithBackoffStrategy(function() {
                        return connect(retryCount + 1);
                    }, {
                        retryCount: retryCount,
                        retryReason: {
                            code: code,
                            reason: reason,
                            wasClean: wasClean
                        }
                    });
                } else {
                    clearInterval(heartbeatIntervalId);
                }
            };
            socket.onerror = function(err) {
                deactivateAllSubscribers();
                if (heartbeatIntervalId) {
                    clearInterval(heartbeatIntervalId);
                }
                if (enabled) {
                    var _current, ref;
                    (ref = (_current = optionsCache.current).onError) === null || ref === void 0 ? void 0 : ref.call(_current, err);
                    // Log error only every 6th attempt to reduce noise
                    if (retryCount % 6 === 0) {
                        var error = castUnknownToError(err);
                        logger.captureException({
                            error: error,
                            message: "watchPage::Connection error. Cannot fetch webinar status",
                            tags: {
                                onair: true
                            }
                        });
                    }
                }
            };
        };
        if (enabled) {
            connect();
        }
        return function() {
            var ref;
            (ref = socketRef.current) === null || ref === void 0 ? void 0 : ref.close(CLOSE_NORMAL_STATUS_CODE);
        };
    }, [
        enabled,
        url
    ]); // eslint-disable-line
    var getSocket = useCallback(function() {
        return socketRef.current;
    }, []);
    return {
        getSocket: getSocket
    };
};
export default useWebsocket;
